export default function () {
    // -----------------------------------------------------------------------
    // template save nav state
    // -----------------------------------------------------------------------

    // on html content load
    window.addEventListener('DOMContentLoaded', function (event) {
        var isactive = JSON.parse(localStorage.getItem('nav-active'));

        if (isactive) {
            document.body.classList.add("nav-active");
        } else {
            document.body.classList.remove("nav-active");
        }

        // load filters visible / hidden    
        var show_filter = JSON.parse(localStorage.getItem('filters-active'));
        if(document.querySelector(".card-list")){
            if (show_filter) {
                document.querySelector(".card-list").classList.add("filters-active");
            } else {
                document.querySelector(".card-list").classList.remove("filters-active");
            }    
        }
    });

    window.addEventListener('load', (event) => {
        document.body.classList.remove("preload");
    });

};
