import app_init from "./app/app_init.js";
import jbox from "./app/jbox.js";
import lists from "./app/lists.js";
import navigation from "./app/navigation.js";
import system_langs from "./app/system_langs.js";
import multilang_fields from "./app/multilang_fields.js";

// load state from local storage and mark page loaded 
app_init(); 

jbox();
navigation();
lists();
system_langs();
multilang_fields();