$.fn.sorter = function (options) {
    var settings = $.extend({        
        sortOrderField: "#sort-order",
        form:null,
        loader:false
    }, options );

    // setup
    var SortTypes = {
        ASC: 1,
        DESC: 2
    };

    var sortList = [];

    // helpers
    function findMaxSortOrder() {
        var ret = 0;
        $(".sort-field").each(function () {
            var sortOrder = $(this).attr("data-sort-order");
            if (sortOrder) {
                ret = Math.max(sortOrder, ret)
            }
        });
        return ret;
    }

    /*
    function reOrder() {
        items = toArray();
        n = 1;
        items.forEach(function (t) {
            t.sortorder = n;

            $(".sort-field").each(function () {
                var sortField = $(this).attr("data-field").toLowerCase();

                if (t.sortfield.toLowerCase() == sortField) {
                    $(this).attr("data-sort-order", t.sortorder);
                    $(this).attr("data-sort-type", t.sorttype);
                }

            });

            n += 1;
        });
    }
    */

    // use .sort-field DOM attributes to change UI
    function updateUI() {
        $(".sort-field").each(function () {
            $(this).find(".sort-order").html("");
            $(this).find(".sort-type").html("");

            if ($(this).attr("data-sort-order")) {
                $(this).find(".sort-order").html($(this).attr("data-sort-order"));
            }

            if ($(this).attr("data-sort-type")) {
                $(this).find(".sort-type").html($(this).attr("data-sort-type") == 1 ? '&#8595' : '&#8593');
            }
        });
    }

    function toArray() {

        // clear fields
        ret = [];

        $(".sort-field").each(function () {
            var sortField = $(this).attr("data-field");
            var sortOrder = $(this).attr("data-sort-order");
            var sortType = $(this).attr("data-sort-type");

            if (sortOrder) {
                ret.push({
                    "sortfield": sortField, 'sortorder': sortOrder, 'sorttype': sortType
                });
            }
        });

        ret.sort(function (a, b) {
            return a.sortorder > b.sortorder ? 1 : -1;
        })

        return ret;
    }

    function asSQL() {
        var sorting = "";
        sortList.forEach(function (item) {
            if (sorting) {
                sorting += ", ";
            }
            sorting += item.sortfield + (item.sorttype == 1 ? " asc" : " desc");
        })

        return sorting;
    }

    function asJsonString() {
        var sorting = "";    
    
        var sorted = sortList.sort(function(a,b){
            return a.sortOrder>b.sortOrder;
        });
        
        var sort_object={};
        sorted.forEach(function (item) {
            sort_object[item.sortfield]=(item.sorttype == 1 ? "asc" : "desc")

        });
    
        return JSON.stringify(sort_object);
    }


    function init() {
        if(!$(settings.sortOrderField).length){
            console.warn("No "+settings.sortOrderField+" field found.");
            return;
        }
        
        // parse to array
        var sort_values = [];
        JSON.parse($(settings.sortOrderField).val(), function(key,value){
            
            if(key){
                sort_values.push({
                    //"sort_order":sort_values.length+1,
                    "sort_field":key,
                    "sort_type": (value.toLowerCase()=="desc"?2:1)
                });    
            }

            return value;
        });
        
        
        sort_values.forEach(function (sortitem) {
            $(".sort-field").each(function () {
                if ($(this).attr("data-field").toLowerCase() == sortitem.sort_field.toLowerCase()) {
                    $(this).attr("data-sort-order", findMaxSortOrder() + 1);
                    $(this).attr("data-sort-type", sortitem.sort_type);
                }
            });

        });

        updateUI();        
    }

    init();
    
    //if(!$("#blocker").length){

    // events
    $(".sort-field").on("click", function (event) {

        if (event.shiftKey) {            
            // set sort number
            if (!$(this).attr("data-sort-order")) {
                $(this).attr("data-sort-order", findMaxSortOrder() + 1)
            }

            // set type
            var sortType = $(this).attr("data-sort-type");
            if (sortType == SortTypes.ASC) {
                $(this).attr("data-sort-type", SortTypes.DESC);
            } else {
                $(this).attr("data-sort-type", SortTypes.ASC);
            }
        }
        

        if (event.ctrlKey) { // remove sorting
            $(".sort-field").each(function () {
                $(this).removeAttr("data-sort-type");
                $(this).removeAttr("data-sort-order");
            });

            // set sort number
            if (!$(this).attr("data-sort-order")) {
                $(this).attr("data-sort-order", findMaxSortOrder() + 1)
            }

            // set type
            var sortType = $(this).attr("data-sort-type");
            if (sortType == SortTypes.ASC) {
                $(this).attr("data-sort-type", SortTypes.DESC);
            } else {
                $(this).attr("data-sort-type", SortTypes.ASC);
            }            
            // reOrder();
        } 
        

        if(!event.ctrlKey && !event.shiftKey){            
            // if not allready selected clear all
            if(!$(this).attr("data-sort-order")){
                $(".sort-field").each(function () {
                    $(this).removeAttr("data-sort-type");
                    $(this).removeAttr("data-sort-order");
                });
            }

            // set sort number
            if (!$(this).attr("data-sort-order")) {
                $(this).attr("data-sort-order", findMaxSortOrder() + 1)
            }

            // set type
            var sortType = $(this).attr("data-sort-type");
            if (sortType == SortTypes.ASC) {
                $(this).attr("data-sort-type", SortTypes.DESC);
            } else {
                $(this).attr("data-sort-type", SortTypes.ASC);
            }
        }
        updateUI();
        //return;

        sortList = toArray();
        //var json_string = asJson();
        $(settings.sortOrderField).val(asJsonString());
        $(settings.form).trigger("submit");
        
        if(settings.loader){        

            var elemDiv = document.createElement('div');
            elemDiv.setAttribute("id", "loading");
            elemDiv.innerHTML = '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
            document.body.appendChild(elemDiv);
        }        
        
    });
};