export default function() {
    jQuery(() => {
        // -----------------------------------------------------------------------
        // change system language
        // -----------------------------------------------------------------------
        $(".language-item").on("click", function (e) {
            e.preventDefault();
            var id = $(this).data("id");
            var url = base_url + "/api/system_language?lang=" + id;

            axios({
                url: url
            }).then(response => {
                var response = parseInt(response.data);
                if (response >= 0) {
                    location.reload();
                }
            }).catch((error) => {
                console.log(error);
            })
        });

    });
}
