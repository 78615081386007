export default function() {
    jQuery(() => {

        $(".hamburger").on("click", function () {
            $(document.body).toggleClass("nav-active");
            localStorage.setItem("nav-active", $(document.body).hasClass("nav-active"));
        })

        $(".menu-item.has-submenu > a").on("click", function (event) {
            event.preventDefault();
            $(this).closest(".menu-item.has-submenu").toggleClass("active");
        });

    });
}
