window.utils = {};

window.utils.toJsonOrValue = function (str) {
    try {
        ret = JSON.parse(str);
        return ret;
    } catch (e) {
        return str;
    }
}

// show validation errors 
window.show_validation_errors = function (errors) {
    var errors_witout_input=[];

    // if field then mark as invalid else add to array 
    for (const property in errors) {
        if($("#" + property).length){
            $("#" + property).addClass("is-invalid");
            $("." + property + "-field").addClass("is-invalid");
            $("." + property + "-error").html(errors[property]);
        } else {
            console.log("innn");
            errors_witout_input.push("- " + errors[property]);
        }
    }

    // show extra warnings on status bar
    if(errors_witout_input.length){
        showStatus("danger",errors_witout_input.join("<br/>"));
    }
}

// save form
// progress bar : https://stackoverflow.com/questions/15328275/show-progressbar-while-loading-pages-using-jquery-ajax-in-single-page-website
window.save_form = function (save_params) {

    save_params = !save_params ? {} : save_params;

    $(".error").html("");
    $("input, .input-group").removeClass("is-invalid");

    if (!save_params.success) {
        save_params.success = function (res) {
            showStatus("success", res.message);
        }
    }

    if (!save_params.error) {
        save_params.error = function (data) {
            data = utils.toJsonOrValue(data.responseText);
            if (data.errors) {
                //console.warn("form validation errors :",data.errors);
                show_validation_errors(data.errors);
            } else {
                showStatus("danger", data);
            }
        };
    }

    $('#form').ajaxSubmit({
        dataType: 'json',
        context: this,
        beforeSend: function () {
            $(".i-save").addClass("fa fa-circle-o-notch fa-spin fa-fw mr-2");
        },
        complete: function () {
            $(".i-save").removeClass("fa fa-circle-o-notch fa-spin fa-fw mr-2");
        },

        success: save_params.success,
        error: save_params.error
    });

    return false;
}


window.RemoveParameterFromUrl=function(url, parameter) {
    return url
        .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
        .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
}
