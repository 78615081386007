export default function() {
    jQuery(() => {
        // -----------------------------------------------------------------------
        // initialize sorting
        // -----------------------------------------------------------------------
        $("#table").sorter({            
            form: "#form-filters",
            //loader:true
        });

        // -----------------------------------------------------------------------
        // change page size
        // -----------------------------------------------------------------------
        $(".list-page-sizes").on("change", () => {
            var pagesize = $(".list-page-sizes").val();
            var url = base_url + "/api/page_size?key=" + encodeURIComponent($(".list-page-sizes").attr("data-list-key")) + "&size=" + pagesize;

            axios({
                url: url
            }).then(response => {
                var page = parseInt(response.data);
                if (page >= 0) {
                    location.href = RemoveParameterFromUrl(location.href, 'page');
                }
            }).catch((error) => {
                console.error(error);
            })
        });

        // -----------------------------------------------------------------------
        // show/hide filters
        // -----------------------------------------------------------------------
        $(".list-filters").on("click", () => {
            $(".card-list").toggleClass("filters-active");
            localStorage.setItem("filters-active", $(".card-list").hasClass("filters-active"));
        });

        // -----------------------------------------------------------------------
        // clear filters
        // -----------------------------------------------------------------------
        $(".list-clear").on("click", (e) => {
            e.preventDefault();
            var url = base_url + "/api/clear_filters?key=" + encodeURIComponent($(".list-page-sizes").attr("data-list-key"));
            axios({
                url: url
            }).then(response => {
                location.href = RemoveParameterFromUrl(location.href, 'page');
            }).catch((error) => {
                console.error(error);
            })
        });




    });
}
