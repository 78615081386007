import jBox from 'jbox';
import 'jbox/dist/jBox.all.css';

// -----------------------------------------------------------------------
// delete popup initialization
// -----------------------------------------------------------------------
export default function() {
    new jBox('Confirm', {
        confirmButton: 'Yes',
        cancelButton: 'No'
    })
};
