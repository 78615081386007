window.showStatus = function (status, text) {
    var description = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var html = "Message";
  
    if (!status) {
      status = "success";
    }
  
    if (text) {
      html = text;
    }
  
    if (status == "success") {
      html = "<i class='fa fa-check'></i>" + html;
    }
  
    if (status == "danger") {
      html = "<i class='fa fa-exclamation'></i>" + html;
    }
  
    if (description) {
      html += "<div style='margin-top:20px; text-align: center; line-height:1.5em'>" + description + "</div>";
    }
  
    var div = document.createElement("div");
    div.setAttribute('class', 'alert alert-custom alert-' + status);
    div.innerHTML = html;
    $('body').append(div);
  
    if (status == "success") {
      setTimeout(function () {
        $('.alert-custom').remove();
      }, 1000);
    } else {
      setTimeout(function () {
        $('.alert-custom').remove();
      }, 2000);
    }
  };
  