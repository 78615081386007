export default function() {
    jQuery(() => {
        // -----------------------------------------------------------------------
        // multilang popup
        // -----------------------------------------------------------------------
        $(".form-group.languages").on("focus click", function (e) {

            // if clicked element is contained on the .lang-popup div then dont fire popup again
            if ($(e.target).parents(".lang-popup").length) {
                return
            }

            // show popup set focus to first field
            $(this).find(".lang-popup")
                .addClass("active")
                .find(".lang-field").first().trigger("focus");
        })

        // close button handle
        $(".lang-popup .close").on("click", (event) => {
            event.stopPropagation();
            $(".lang-popup").removeClass("active");
        });

        // escape key - tab cycle handle
        $(".lang-popup .lang-field").on("keydown", function (e) {

            // escape
            if (e.key == "Escape") {
                e.preventDefault();
                $(".lang-popup").removeClass("active");
                return;
            }

            if (e.key == "Enter") {
                if($(this).is("input")){                    
                    e.preventDefault();                     
                    return;
                }                
            }

            // tab
            if (e.key == "Tab" && !e.shiftKey && $(this).is($(".lang-popup.active .lang-field").last())) {
                e.preventDefault();
                $(".lang-popup.active .lang-field").first().trigger("focus");
            }

            // shift tab
            if (e.key == "Tab" && e.shiftKey && $(this).is($(".lang-popup.active .lang-field").first())) {
                e.preventDefault();
                $(".lang-popup.active .lang-field").last().trigger("focus");
            }
        });

        // update main field on each key up 
        $(".lang-popup .lang-field[data-for]").on("keyup", function (e) {

            const fieldToUpdate = $(this).data("for");
            const value = $(this).val();

            // if field not empty update with value 
            if (value) {
                $("." + fieldToUpdate).removeClass("default-language");
                $("." + fieldToUpdate).html(value.replace(/\r\n|\r|\n/g, "</br>"));
            } else { // else show default value
                $("." + fieldToUpdate).addClass("default-language");
                $("." + fieldToUpdate).html($("." + fieldToUpdate).data("default-value").replace(/\r\n|\r|\n/g, "</br>"));
            }
        });


    });
}
